export enum Feature {
  ADDITIONAL_LANGS_ES_PT = 'additional-langs-es-pt',
  CAREER_RECS_BY_CAREER_INTERESTS = 'career-recs-by-career-interests',
  CUSTOM_REGISTRATION_EPG_ADMIN = 'custom-registration-epg-admin',
  EMPLOYER_PORTAL = 'employer-portal',
  EXTRACT_SKILLS_FROM_JOB_DESCRIPTION = 'extract-skills-from-job-description',
  FORMS_IMPROVEMENTS = 'forms_improvements',
  FORMS_V3_DEFAULT_QUESTIONS = 'forms-v3-default-questions',
  HIDE_INTERCOM = 'hide-intercom',
  JOB_SEARCH_LOCATION_CONFIG = 'configurable_job_search_location',
  LIVE_TRANSLATION = 'live_translation',
  RECRUITER_OUTREACH = 'recruiter-outreach',
  WORK_RECOMMENDER_V2_BEST_MATCH_SORT = 'work_recommender_v2_best_match_sort',
  WORK_RECOMMENDER_V2_LANDING_PAGE = 'work_recommender_v2_landing_page',
  WORK_RECOMMENDER_RELEVANT_AND_RELATED = 'work_rec_relevant_related',
  LLM_RESUME_SERVICE = 'llm_resume_service',
  LANGUAGE_FILTER = 'language_filter',
  LOOKER_INSIGHTS = 'looker_insights',
  LEARNING_SIDE_PANEL_SKILLS_AND_CAREERS = 'learning_side_panel_skills_and_careers',
  LEARNING_SIDE_PANEL_LEVEL = 'learning_side_panel_level',
  INVITE_LINKS_VALIDATE_EMAIL_DOMAINS = 'invite_links_validate_email_domains',
  WORK_CONFIGURATION = 'work_configuration',
  HIDE_CAREER_DEMAND = 'hide_career_demand',
  LIVE_TRANSLATION_SERVICE = 'live-translation-service',
  EMPLOYER_PORTAL_TAGS = 'employer_portal_tags',
  ATS_EASY_APPLY = 'enable_ats_easy_apply',
  POLICY_DISPLAY_SIGN_UP = 'policy_display_sign_up',
}
