/**
 * Constants to use for routes
 */
export const enum UnauthenticatedRoute {
  SIGN_IN = '/sign-in',
  SIGN_UP = '/sign-up',
  VERIFY_CODE = '/verify-code',
}

export const enum EndUserRoute {
  HOME = '/home',
  LEARNING = '/learning',
  LEARNING_RECOMMENDED = '/learning/recommended',
  LEARNING_SAVED = '/learning/saved',
  LEARNING_SEARCH = '/learning/search',
  RESOURCES = '/resources',
  RESOURCES_RECOMMENDED = '/resources/recommended',
  RESOURCES_SAVED = '/resources/saved',
  RESOURCES_SEARCH = '/resources/search',
  CAREERS = '/careers',
  WORK_RECOMMENDED = '/work/recommended',
  WORK_FIND_JOBS = '/work/find-jobs',
  WORK_MY_JOBS = '/work/my-jobs',
  WORK_JOB_TRACKER = '/work/job-tracker',
  CAREERS_BROWSE = '/careers/browse',
  CAREERS_SAVED = '/careers/saved',
  CAREERS_MY_CAREER_PATH = '/careers/my-career-path',
  ROADMAP = '/roadmap',
  ROADMAP_UPCOMING = '/roadmap/upcoming',
  ROADMAP_COMPLETED = '/roadmap/completed',
  ROADMAP_ALL = '/roadmap/all',
  ACCOUNT = '/account',
  PASSPORT = '/my-career-passport',
  PASSPORT_EXPERIENCE = '/my-career-passport/experience',
  PASSPORT_SKILLS = '/my-career-passport/skills',
  PASSPORT_INTERESTS = '/my-career-passport/interests',
  PASSPORT_PERSONAL_INFO = '/my-career-passport/personal-information',
}

export const enum AdminRoutes {
  BRANDING = '/branding',
  BUSINESS_SERVICES = '/business-services',
  BUSINESS_SERVICES_TALENT = '/business-services/talent',
  CAREERS_CONFIG = '/careers-config',
  CREATE_MANUAL_JOB = '/business-services/jobs/manual',
  SIGN_UP_FORM = '/sign-up-form',
  DEFAULT_FORM_QUESTIONS = '/default-form-questions',
  FORMS = '/forms',
  LEARNING_CONFIG = '/learning-config',
  MANAGE_COACHES = '/manage/coaches',
  MANAGE_EMPLOYERS = '/business-services/employers',
  MANAGE_INVITES = '/manage/invites',
  MANAGE_JOBS = '/business-services/jobs',
  MANAGE_RECRUITERS = '/business-services/recruiters',
  MANAGE_REPORTS = '/manage/reports',
  MANAGE_TAGS = '/manage/tags',
  RESOURCES_CONFIG = '/resources-config',
  YOUR_ROADMAP_DRAFT = '/your-roadmap/draft',
  YOUR_ROADMAP_PUBLISHED = '/your-roadmap/published',
  CONFIGURATIONS_LEARNING = '/configurations/learning',
  CONFIGURATIONS_RESOURCES = '/configurations/resources',
  CONFIGURATIONS_CAREERS = '/configurations/careers',
  CONFIGURATIONS_BRANDING = '/configurations/branding',
  CONFIGURATIONS_WORK = '/configurations/work',
  CONFIGURATIONS_ROADMAP = '/configurations/roadmap',
  CONFIGURATIONS_ROADMAP_DRAFT = '/configurations/roadmap/draft',
  CONFIGURATIONS_ROADMAP_PUBLISHED = '/configurations/roadmap/published',
  CONFIGURATIONS_FORMS = '/configurations/forms',
  CONFIGURATIONS_REGISTRATION = '/configurations/registration',
  CONFIGURATIONS_INSIGHTS = '/configurations/insights',
  CONFIGURATIONS = '/configurations',
  PAGE_NOT_FOUND = '/page-not-found',
  INSIGHTS = '/insights',
  INSIGHTS_USERS = '/insights/users',
  INSIGHTS_ACTIVITY = '/insights/activity',
  INSIGHTS_CUSTOM = '/insights/custom',
}

export const enum CoachRoutes {
  MANAGE = '/manage',
}

export const enum RecruiterRoutes {
  MANAGE_JOBS = '/manage/jobs',
  CREATE_MANUAL_JOB = '/manage/jobs/manual',
  FIND_TALENT = '/search/talent',
  ACCOUNT = '/account',
  PAGE_NOT_FOUND = '/page-not-found',
}

export const enum RoadmapRoutes {
  UPCOMING = '/roadmap/upcoming',
  COMPLETED = '/roadmap/completed',
  ALL = '/roadmap/all',
}

export const enum FormRoutes {
  NEW_FORM = '/forms/new',
}

export const sharedJobLinkRoute = '/job-link';

export const generateJobLink = ({
  tenantCode,
  jobId,
}: {
  tenantCode: string;
  jobId: string;
}) => {
  return `${window.location.origin}${sharedJobLinkRoute}/${jobId}?tc=${tenantCode}`;
};
