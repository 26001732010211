import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import ChainedBackend from 'i18next-chained-backend';
import LocizeBackend from 'i18next-locize-backend';
import resourcesToBackend from 'i18next-resources-to-backend';
import LastUsed from 'locize-lastused';
import { locizePlugin } from 'locize';

import translationsEn from '../assets/locales/en-US/translation.json';
import translationsFr from '../assets/locales/fr-CA/translation.json';
import { formatTranslatedDuration, getDurationUnit } from '../utils/duration';
import { formatSalary } from '../utils/currency';

const bundledResources = {
  'en-US': {
    translation: translationsEn,
  },
  'fr-CA': {
    translation: translationsFr,
  },
};

// Available locales map type
type FFAILocales = {
  [key: string]: {
    label: string;
    currency: string;
    distance?: {
      imperial: {
        singular: string;
        plural: string;
        symbol: string;
      };
      metric: {
        singular: string;
        plural: string;
        symbol: string;
      };
    };
  };
};

/**
 * All languages supported in the application by i18n engine.
 *
 * @remarks This does not mean all users will be able to choose from these langauges as app language.
 * To see what languages are avaliable for user selection in the UI (due to differences in app / tenant feature configs), please use:
 * `const { availableLangsForUser } = useLocales()`
 */
export const APP_SUPPORTED_LANGS = [
  'en-US',
  'en-GB', // TODO: Remove en-GB from the list of supported languages
  'fr-CA',
  'es-ES',
  'pt-PT',
] as const;

export type AppSupportedLangs = (typeof APP_SUPPORTED_LANGS)[number];

export const HOUR_12_LOCALES = ['en-US', 'en-CA'];

// Currency codes found here:
// https://en.wikipedia.org/wiki/ISO_4217
export const INTERNATIONAL_LOCALES: FFAILocales = {
  'en-US': {
    label: 'EN',
    currency: 'USD',
    distance: {
      imperial: {
        singular: 'mile',
        plural: 'miles',
        symbol: 'mi',
      },
      metric: { singular: 'kilometer', plural: 'kilometers', symbol: 'km' },
    },
  },
  'en-GB': {
    label: 'EN (UK)',
    currency: 'GBP',
    distance: {
      imperial: {
        singular: 'mile',
        plural: 'miles',
        symbol: 'mi',
      },
      metric: { singular: 'kilometer', plural: 'kilometers', symbol: 'km' },
    },
  },
  'fr-CA': {
    label: 'FR',
    currency: 'USD',
    distance: {
      imperial: { singular: 'mille', plural: 'milles', symbol: 'mi' },
      metric: { singular: 'kilomètre', plural: 'kilomètres', symbol: 'km' },
    },
  },
  'es-ES': {
    label: 'ES',
    currency: 'USD',
    distance: {
      imperial: { singular: 'milla', plural: 'millas', symbol: 'mi' },
      metric: { singular: 'kilómetro', plural: 'kilómetros', symbol: 'km' },
    },
  },
  'pt-PT': {
    label: 'PT',
    currency: 'USD',
    distance: {
      imperial: { singular: 'milha', plural: 'milhas', symbol: 'mi' },
      metric: { singular: 'quilômetro', plural: 'quilômetros', symbol: 'km' },
    },
  },
};

export const DEFAULT_LOCALE = 'en-US';

const LANGUAGE_MAP = {
  EN: { i18nLabel: 'en-US', language: 'english' },
  FR: { i18nLabel: 'fr-CA', language: 'french' },
  ES: { i18nLabel: 'es-ES', language: 'spanish' },
  PT: { i18nLabel: 'pt-PT', language: 'portuguese' },
};

export type LanguageLabel = keyof typeof LANGUAGE_MAP;

export const getFullLabels = (label: LanguageLabel) => {
  const languageInfo = LANGUAGE_MAP[label];

  if (!languageInfo) return label;

  const { i18nLabel, language } = languageInfo;

  if (i18nLabel === i18n.language) {
    return i18n.t(`${language}.language.full.label`);
  }

  switch (label) {
    case 'EN':
      return `English (${i18n.t('english.language.full.label', 'English')})`;
    case 'FR':
      return `Français (${i18n.t('french.language.full.label', 'French')})`;
    case 'ES':
      return `Español (${i18n.t('spanish.language.full.label', 'Spanish')})`;
    case 'PT':
      return `Português (${i18n.t(
        'portuguese.language.full.label',
        'Portuguese'
      )})`;
    default:
      return label;
  }
};

const isProduction = import.meta.env.VITE_ENV === 'prod-us';
const isSandbox = import.meta.env.VITE_ENV === 'sandbox';
const isLocal = import.meta.env.VITE_ENV === 'local';

const locizeOptions = {
  projectId: import.meta.env.VITE_LOCIZE_PROJECTID!,
  apiKey: import.meta.env.VITE_LOCIZE_APIKEY!,
  referenceLng: DEFAULT_LOCALE,
  version: isSandbox ? 'staging' : isProduction ? 'production' : 'latest',
  allowedAddOrUpdateHosts: !isProduction
    ? ['app-dev.futurefit.ai', 'localhost']
    : [],
};

if (!isProduction) {
  // locize-lastused
  // sets a timestamp of last access on every translation segment on locize
  // -> safely remove the ones not being touched for weeks/months
  // https://github.com/locize/locize-lastused
  i18n.use(LastUsed);
}

i18n
  // locize-editor
  // InContext Editor of locize
  .use(locizePlugin)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)

  .use(ChainedBackend)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    backend: {
      backends: [
        // i18next-locize-backend
        // loads translations from your project, saves new keys to it (saveMissing: true)
        // https://github.com/locize/i18next-locize-backend
        LocizeBackend,
        // fallback in case locize CDN fails
        resourcesToBackend(bundledResources),
      ],
      backendOptions: [locizeOptions],
    },
    debug: !isProduction ? true : false,
    fallbackLng: DEFAULT_LOCALE,
    supportedLngs: APP_SUPPORTED_LANGS,
    load: 'currentOnly',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    keySeparator: !isProduction ? false : '.',
    locizeLastUsed: locizeOptions,
    saveMissing: isLocal,
    updateMissing: isLocal,
  });

i18n?.services?.formatter?.add('CURRENCY', (value, lng) => {
  // set to our fallback if undefined
  if (!lng || !(APP_SUPPORTED_LANGS as any as string[]).includes(lng))
    lng = 'en-US';
  const currency = INTERNATIONAL_LOCALES[lng].currency;

  // format the number using the currency style
  return new Intl.NumberFormat(lng, {
    style: 'currency',
    currency,
  }).format(value);
});

i18n?.services?.formatter?.add(
  'SALARY',
  (value, lng, options: { [key: string]: any; currencyCode?: string }) => {
    // set to our fallback if undefined
    if (!lng || !(APP_SUPPORTED_LANGS as any as string[]).includes(lng))
      lng = 'en-US';
    const currency = options.currencyCode
      ? options.currencyCode
      : INTERNATIONAL_LOCALES[lng].currency;

    // format the number using the currency style
    return formatSalary(value, { lng, currency });
  }
);

i18n?.services?.formatter?.add('SALARY_FILTER', (value, lng) => {
  // set to our fallback if undefined
  if (!lng || !(APP_SUPPORTED_LANGS as any as string[]).includes(lng))
    lng = 'en-US';
  const currency = INTERNATIONAL_LOCALES[lng].currency;

  // format the number using the currency style
  return new Intl.NumberFormat(lng, {
    style: 'currency',
    currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(value);
});

i18n?.services?.formatter?.add('DURATION_FILTER', (value, lng) => {
  // Assign 1 as parsedNumber for 'a month ago' / 'a year ago', so that Intl.RelativeTimeFormat() can translate properly (it only takes in numeric data)
  const parsedNumber = value.includes('a ') ? 1 : parseInt(value);

  const formatted = new Intl.RelativeTimeFormat(lng, {
    localeMatcher: 'best fit',
    numeric: 'always',
    style: 'long',
  }).formatToParts(-parsedNumber, getDurationUnit(value));

  return formatTranslatedDuration(formatted, value);
});

i18n?.services?.formatter?.add(
  'DISTANCE_FORMATTER',
  (value, lng, { isImperial }) => {
    // conversion is based on user location and passed through the isImperial option
    // translation is based on the user's selected language
    // '10' -> '10 miles' for en-US and '16 kilomètres' for fr-CA
    // edge case -> user selecting spanish located in US should see 10 millas
    // set to our fallback if undefined
    if (!lng || !(APP_SUPPORTED_LANGS as any as string[]).includes(lng))
      lng = 'en-US';
    // value is expected to be in miles and in a string format
    let parsedValue = parseInt(value);
    const distanceUnitType = isImperial ? 'imperial' : 'metric';
    if (distanceUnitType === 'metric') {
      parsedValue = Math.round(parsedValue * 1.6);
    }
    const distanceUnit = INTERNATIONAL_LOCALES[lng].distance?.[
      distanceUnitType
    ][parsedValue === 1 ? 'singular' : 'plural'] as string;
    return `${parsedValue} ${distanceUnit}`;
  }
);

// Add an event listener for language change
i18n.on('languageChanged', (lng: string) => {
  document.documentElement.lang = lng;
});

export default i18n;
